var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel-evaluation-content"
  }, [_c('div', {
    staticClass: "d-flex evaluation-content-title col-md-6 col-8"
  }, [_c('div', {
    staticClass: "icon-evaluation-content"
  }), _c('div', {
    staticClass: "txt-evaluation-content-title"
  }, [_vm._v(_vm._s(_vm.$t(_vm.type)))])]), _c('div', {
    staticClass: "txt-evaluation-content col-md-11"
  }, [_vm._v(_vm._s(_vm.content))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }