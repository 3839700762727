var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "txt-analysis-info"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chart-line"
    }
  }), _vm._v(" LV. " + _vm._s(_vm.value) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }