<template>
  <div class="view-evaluation">
    <div class="are-evaluation-card">
      <div class="blk-evaluation-head">
        <div class="evaluation-title d-flex justify-content-center align-items-center">
          <h3 class="h3-evaluation-title">{{ specificClassroomBasicInfo.material.title }}</h3>
        </div>
        <div
          class="panel-viewevaluation-info d-flex justify-content-around align-items-center"
        >
          <vip-classroom-info :page="'evaluation'" />
        </div>
      </div>
      <div class="blk-evaluation-body">
        <suggestion-and-score-content :specific-classroom-basic-info="specificClassroomBasicInfo" />
        <div v-for="list in suggestionContentList" :key="list">
          <suggestion-content :type="list" :content="specificClassroomBasicInfo.evaluation[list]" />
        </div>
      </div>
      <div class="blk-evaluation-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-outline-primary"
          @click="() => {this.$router.go(-1);}"
        >
          {{ $t('router_back') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import i18n from './lang';
import { mapState } from 'vuex';
import VipClassroomInfo from '@/components/class/evaluations/vipClassroomInfo';
import SuggestionAndScoreContent from './suggestionAndScoreContent';
import SuggestionContent from './suggestionContent';

export default {
  name: 'ViewEvaluation',

  components: {
    VipClassroomInfo,
    SuggestionAndScoreContent,
    SuggestionContent,
  },

  i18n,

  data() {
    return {
      suggestionContentList: [
        'feedback',
        'hb_suggestion',
      ],
    };
  },

  computed: {
    ...mapState({
      specificClassroomBasicInfo: (state) => state.classroom.specificClassroomBasicInfo,
    }),
  },

  created() {
    // 若無資料直接回到學習軌跡
    (!this.specificClassroomBasicInfo.class.id) ? this.$router.push({ name: 'classRecords' }) : '';
  },
};
</script>

<style lang="scss">
@import "@/styles/class/evaluation";
</style>
