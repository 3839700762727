export default {
  messages: {
    en: {
      overall_level: 'Overall Level',
      consultant_rating: 'Consultant Rating',
      express_time_rating: 'I have been given enough time to express my thoughts',
      advice_rating: 'Adequate feedback from the consultant ( correcting mistakes/giving encouragement)',
      overall_time_rating: 'Consultant\'s time management on the material',
      material_rating: 'Class material satisfaction',
      communication_rating: 'Connection stability',
      feedback: 'Feedback',
      hb_suggestion: 'HB Suggestion',
      not_filled: 'Not Filled',
      no_opportunity: 'No Opportunity',
      little_opportunity: 'Little Opportunity',
      have_opportunity: 'Have Opportunity',
    },

    tw: {
      overall_level: '我對於本堂課的整體滿意程度',
      consultant_rating: '顧問有即時給予我糾正與回饋',
      express_time_rating: '顧問的教學內容時間分配與掌握',
      advice_rating: '我有分配到適當的表達時間',
      overall_time_rating: '顧問平均分數',
      material_rating: '對於本堂課程的教材滿意程度',
      communication_rating: '對於本堂課程的通訊狀況滿意程度s',
      feedback: '與客服的悄悄話',
      hb_suggestion: '想跟顧問說點什麼呢？',
      not_filled: '未填寫',
      no_opportunity: '完全沒機會表達',
      little_opportunity: '很少表達的機會',
      have_opportunity: '能表達的時間剛剛好',
    },
  },
};
