<template lang="pug">
div
  .panel-evaluation-content.flex-wrap.d-flex(v-for='data in evaluation_data_list' :key='data.key')
    .d-flex.evaluation-content-title.col-md-6.col-8
      .icon-evaluation-content
      .txt-evaluation-content-title
          | {{ data.title }}
    b-button.button-evaluation.button-unfilled(v-if='\
    data.checkUnfilled &&\
    checkUnfilled(\
    specificClassroomBasicInfo.class.time,\
    specificClassroomBasicInfo.evaluation[data.key]\
    )\
    ') {{ $t('not_filled') }}
    b-button.button-evaluation(v-else='' :class='onReturnButton(specificClassroomBasicInfo.evaluation[data.key])')
      | {{ specificClassroomBasicInfo.evaluation[data.key] }}
    .evaluation-suggestion.col-md-4.col-12(v-if='data.suggestion_key !== null')
      | {{ specificClassroomBasicInfo.evaluation[data.suggestion_key] }}
</template>

<script type="text/javascript">
import moment from 'moment';
import i18n from './../lang';

export default {
  name: 'SuggestionAndScoreContent',

  props: {
    // eslint-disable-next-line
    specificClassroomBasicInfo: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      evaluation_data_list: [
        {
          title: this.$t('overall_level'),
          key: 'overall_level',
          suggestion_key: null,
          checkUnfilled: false,
        },
        {
          title: this.$t('consultant_rating'),
          key: 'consultant_rating',
          suggestion_key: null,
          checkUnfilled: false,
        },
        {
          title: this.$t('express_time_rating'),
          key: 'express_time_rating',
          suggestion_key: 'suggestion_express_time_rating',
          checkUnfilled: true,
        },
        {
          title: this.$t('advice_rating'),
          key: 'advice_rating',
          suggestion_key: 'suggestion_advice_rating',
          checkUnfilled: true,
        },
        {
          title: this.$t('overall_time_rating'),
          key: 'overall_time_rating',
          suggestion_key: 'suggestion_overall_time_rating',
          checkUnfilled: true,
        },
        {
          title: this.$t('material_rating'),
          key: 'material_rating',
          suggestion_key: 'suggestion_material_rating',
          checkUnfilled: true,
        },
        {
          title: this.$t('communication_rating'),
          key: 'communication_rating',
          suggestion_key: 'suggestion_communication_rating',
          checkUnfilled: true,
        },
      ],
    };
  },

  methods: {
    onReturnButton(rating) {
      if (rating >= 80) {
        return 'button-good';
      } else if (rating >= 60) {
        return 'button-soso';
      } else if (rating < 60) {
        return 'button-bad';
      } else {
        return 'button-unfilled';
      }
    },

    checkUnfilled(creat_time, rating) {
      // 2020-05-26 改成新版時間
      return (
        rating === 0 || (rating === null && moment(creat_time).isBefore('2020-05-26'))
      );
    },
  },
};
</script>
