<template>
  <div class="row are-analysis-info">
    <span v-for="info in infoList" :key="info.component" class="col-6 col-md-3 txt-analysis-info">
      <component :is="info.component" :value="info.value" />
    </span>
  </div>
</template>

<script type="text/javascript">
import ClassTime from './classTime';
import ConsultantName from './consultantName';
import VipEnglishName from './vipEnglishName';
import VipLevel from './vipLevel';
import { mapState } from 'vuex';

export default {
  name: 'ClassroomInfo',

  components: {
    ClassTime,
    ConsultantName,
    VipEnglishName,
    VipLevel,
  },

  props: {
    // eslint-disable-next-line
    page: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState({
      specificClassroomBasicInfo: (state) => state.classroom.specificClassroomBasicInfo,
      specificClassroomAnalysisData: (state) => state.classroom.specificClassroomAnalysisData,
    }),

    infoList(){
      const infoMap = {
        evaluation: [
          { component: 'ClassTime', value: this.specificClassroomBasicInfo.class.time },
          { component: 'ConsultantName', value: this.specificClassroomBasicInfo.consultant.name },
        ],
        viewAnalysis: [
          { component: 'ClassTime', value: this.specificClassroomAnalysisData.created_at },
          { component: 'ConsultantName', value: this.specificClassroomAnalysisData.hb_english_name },
          { component: 'VipEnglishName', value: this.specificClassroomAnalysisData.vip_english_name },
          { component: 'VipLevel', value: this.specificClassroomAnalysisData.average_level },
        ],
      };
      return infoMap[this.page];
    },
  },

  created() {},

  mounted() {},

  methods: {},
};
</script>
