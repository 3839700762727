<template>
  <span class="txt-analysis-info">
    <font-awesome-icon icon="user" /> {{ value }}
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'ConsultantName',

  components: {},

  props: {
    // eslint-disable-next-line
    value: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
};
</script>
