var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row are-analysis-info"
  }, _vm._l(_vm.infoList, function (info) {
    return _c('span', {
      key: info.component,
      staticClass: "col-6 col-md-3 txt-analysis-info"
    }, [_c(info.component, {
      tag: "component",
      attrs: {
        "value": info.value
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }