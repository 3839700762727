<template lang="pug">
.panel-evaluation-content
  .d-flex.evaluation-content-title.col-md-6.col-8
    .icon-evaluation-content
    .txt-evaluation-content-title {{ $t(type) }}
  .txt-evaluation-content.col-md-11
    | {{ content }}
</template>

<script type="text/javascript">
import i18n from './../lang';

export default {
  name: 'SuggestionContent',

  props: {
    type: {
      type: String,
      default: function() {
        return '';
      },
    },

    content: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  i18n,

  data() {
    return {};
  },

  methods: {
    // type="feedback" 需檢查
    checkVipRole(vip_data){
      let i;
      for (i = 0; i < vip_data.length; i++){
        if (vip_data[i].role_id === 8 || vip_data[i].role_id === 21 || vip_data[i].role_id === 37 || vip_data[i].role_id === 44 || vip_data[i].role_id === 45 || vip_data[i].role_id === 47){
          return true;
        }
      }
    },
  },
};
</script>
