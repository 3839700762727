var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-evaluation"
  }, [_c('div', {
    staticClass: "are-evaluation-card"
  }, [_c('div', {
    staticClass: "blk-evaluation-head"
  }, [_c('div', {
    staticClass: "evaluation-title d-flex justify-content-center align-items-center"
  }, [_c('h3', {
    staticClass: "h3-evaluation-title"
  }, [_vm._v(_vm._s(_vm.specificClassroomBasicInfo.material.title))])]), _c('div', {
    staticClass: "panel-viewevaluation-info d-flex justify-content-around align-items-center"
  }, [_c('vip-classroom-info', {
    attrs: {
      "page": 'evaluation'
    }
  })], 1)]), _c('div', {
    staticClass: "blk-evaluation-body"
  }, [_c('suggestion-and-score-content', {
    attrs: {
      "specific-classroom-basic-info": _vm.specificClassroomBasicInfo
    }
  }), _vm._l(_vm.suggestionContentList, function (list) {
    return _c('div', {
      key: list
    }, [_c('suggestion-content', {
      attrs: {
        "type": list,
        "content": _vm.specificClassroomBasicInfo.evaluation[list]
      }
    })], 1);
  })], 2), _c('div', {
    staticClass: "blk-evaluation-footer d-flex justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function () {
        _this.$router.go(-1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('router_back')) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }