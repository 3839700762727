<template>
  <span class="txt-analysis-info">
    <font-awesome-icon icon="chart-line" /> LV. {{ value }}
  </span>
</template>

<script type="text/javascript">
export default {
  name: 'VipLevel',

  components: {},

  props: {
    // eslint-disable-next-line
    value: {
      type: Number,
      default: function() {
        return 0;
      },
    },
  },
};
</script>
